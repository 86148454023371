<template>
  <v-app :class="[mobileHeader, 'principal-app']">
    <v-col align-self="center" class="principal-col">
      <v-main>
        <v-col v-if="!mobileHeader" class="pa-0">
          <Navigation />
          <router-view />
        </v-col>
        <v-col v-else class="pa-0">
          <Mobile />
        </v-col>
      </v-main>
      <Footer />
    </v-col>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Mobile from "@/components/NavigationMobile.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Navigation,
    Mobile,
    Footer,
  },
  data: () => ({}),
  computed: {
    mobileHeader() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  metaInfo() {
    return {
      title: "Sicom Electronics",

      meta: [
        {
          name: "Sicom Electronics",
          content:
            "Nos dedicamos al diseño y manufactura de soluciones IoT con IA, para el segmento consumidores y profesional. Nuestro negocio es hacer simple la comunicación entre cosas.",
        },
      ],
    };
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400&family=Roboto&family=Source+Sans+Pro&display=swap");
html,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DM Sans" !important;
}
span {
  font-family: "DM Sans" !important;
}
.principal-app {
  background-color: #fbfbfb !important;
}
.principal-col {
  width: 1600px;
  background-color: white;
  padding: 0px;
}
</style>
