<template>
  <v-col class="pa-0 index-col" cols="auto">
    <v-img src="@/assets/Home/Header.png" class="index-img"></v-img>
    <!-- <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12" class="index-col2"></v-col> -->
  </v-col>
</template>
<script>
export default {
  data: () => ({}),
};
</script>
<style>
.index-col {
  margin-bottom: 0.625vw;
}
@media screen and (max-width: 427px) {
  .index-img {
    height: 460px;
    /* width: 425px; */
  }
  /* .index-col {
    margin-bottom: 0.925vw;
  } */
  /* .index-col2 {
    margin-bottom: 0.925vw;
  } */
}
</style>
