<template>
  <v-app-bar class="application-bar" flat height="85">
    <v-row no-gutters justify="center" align="center">
      <v-img
        v-if="!hideLogo"
        :class="[responsiveLogoStyle, `shrink `]"
        contain
        @click="$router.push('/')"
        :src="logo"
      ></v-img>

      <v-spacer></v-spacer>

      <v-row v-if="!hideMenu" no-gutters justify="center">
        <v-col
          v-for="item in menuBars.items"
          :key="item.title"
          class="pa-0 ma-0"
          cols="auto"
        >
          <v-menu offset-y transition="slide-y-transition" open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text color="transparent" v-bind="attrs" v-on="on">
                <a  class="header-menu-btn"
                    :href="item.url ? item.url : null"
                >
                  {{ item.title }}
                </a>
              </v-btn>
            </template>

            <v-list-item
              link
              class="fontSicom application-bar"
              v-for="category in item.categories"
              :key="category.title"
            >
              <v-list-item-title>
                <a
                  class="header-submenu-btn"
                  :href="category.url ? category.url : null"
                >
                  {{ category.title }}
                </a>
              </v-list-item-title>
            </v-list-item>
          </v-menu>
        </v-col>
      </v-row>

      <v-spacer></v-spacer>

      <v-col v-if="!hideLang" class="category-col" cols="auto">
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="language-btn" text v-bind="attrs" v-on="on">
                <v-icon
                  class="web-language-icon"
                  size="22px"
                  :style="{ color: colorIcon }"
                >
                  mdi-web
                </v-icon>
                <span
                  v-if="responsiveLangText"
                  class="language-text"
                  :style="{ color: colorText }"
                >
                  {{ currentLang }}
                </span>
                <v-icon size="14px" :style="{ color: colorText }"
                  >mdi-chevron-down</v-icon
                >
              </v-btn>
            </template>
            <v-list class="language-list">
              <v-list-item
                class="language-list-item"
                v-for="(item, index) in globalLanguages.items"
                :key="index"
                link
                hide-details
                @click="changeLang(item)"
              >
                <v-list-item-title class="fontSicom language-list-item-title">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="auto" class="search-btn-margin category-col">
        <v-btn
          v-if="!hideSearchBtn && !active"
          @click="active = true"
          class="search-btn"
          text
          icon
        >
          <eva-icon
            name="search"
            animation="pulse"
            fill="rgb(49,97,162)"
          ></eva-icon>
        </v-btn>
        <v-col
          v-if="active && $vuetify.breakpoint.lgAndUp"
          class="search-textfield"
        >
          <v-text-field
            v-if="active"
            v-click-outside="onClickOutside"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            :color="colorIcon"
            outlined
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-col>

      <v-col class="end-col" cols="auto"></v-col>
    </v-row>

    <v-col
      v-if="active && $vuetify.breakpoint.mdAndDown"
      class="search-textfield"
    >
      <v-text-field
        v-if="active"
        v-click-outside="onClickOutside"
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        :color="colorIcon"
        outlined
        single-line
        hide-details
      ></v-text-field>
    </v-col>
  </v-app-bar>
</template>

<script>
export default {
  name: "Header",
  props: {
    logo: {
      default: require("@/assets/SICOM2020.png"),
      require: false,
    },
    logo2: {
      default: require("@/assets/sicomlogomin.png"),
      require: false,
    },
    hideSearchBtn: {
      type: Boolean,
      default: false,
      require: true,
    },
    hideLogo: {
      type: Boolean,
      default: false,
      require: true,
    },
    hideMenu: {
      type: Boolean,
      default: false,
      require: true,
    },
    hideLang: {
      type: Boolean,
      default: false,
      require: true,
    },
  },
  data: () => ({
    number: 100,
    username: undefined,
    active: false,
    search: "",
    currentLang: "Español",
    userMenuItems: [
      {
        text: "Instance Details",
        icon: "mdi-magnify",
        slug: "details",
      },
      {
        text: "Instance Configuration",
        icon: "mdi-wrench",
        slug: "generalConfig",
      },
      {
        text: "Account Settings",
        icon: "mdi-account-box",
        slug: "myAccount",
      },
    ],
  }),

  computed: {
    globalLanguages() {
      return {
        items: [
          { title: this.$i18n.t("Global_Language_Esp"), value: "es" },
          { title: this.$i18n.t("Global_Language_Eng"), value: "en" },
        ],
      };
    },

    menuBars() {
      return {
        items: [
          {
            title: this.$i18n.t("Products"),
            url: "https://smartcity.sicomelectronics.com",
            categories: [
              {
                title: this.$i18n.t("Lighting"),
                url: "https://lighting.sicomelectronics.com",
              },
              {
                title: this.$i18n.t("ElectricSupplies"),
                url: "https://matelec.sicomelectronics.com",
              },
            ],
          },
          {
            title: this.$i18n.t("Services"),
            // url: "https://matelec.sicomelectronics.com",
            categories: [
              {
                title: this.$i18n.t("SmartCity"),
                url: "https://smartcity.sicomelectronics.com",
              },
            ],
          },
        ],
      };
    },

    colorIcon() {
      return this.color
        ? this.color + "!important"
        : "rgb(49,97,162) !important";
    },
    colorText() {
      return this.textColor
        ? this.textColor + "!important"
        : "rgb(41, 73, 99) !important";
    },
    responsiveHeight() {
      return this.$vuetify.breakpoint.smAndUp ? 85 : 68;
    },
    responsiveLogoStyle() {
      return this.$vuetify.breakpoint.mdAndDown
        ? `logoSicom-smAndDown`
        : `logoSicom-smAndUp`;
    },
    responsiveLangText() {
      return this.$vuetify.breakpoint.smAndUp;
    },
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang.value;
      const arrLang = [
        { title: this.$i18n.t("Global_Language_Esp"), value: "es" },
        { title: this.$i18n.t("Global_Language_Eng"), value: "en" },
      ];
      const language = arrLang.find((item) => item.value === lang.value);
      this.currentLang = language.title;
    },
    navigate(route) {
      this.$router.replace({ name: route });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onClickOutside() {
      this.active = false;
    },
  },
};
</script>

<style scoped>
.fontSicom {
  font-family: DM Sans;
}
.application-bar {
  background-color: rgb(255, 255, 255) !important;
}
.logoSicom-col {
  justify-content: center;
  align-items: center;
}
.logoSicom-col-smAndDown {
  margin-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoSicom-smAndUp {
  cursor: pointer;
  width: 140px;
  height: 65px;
}
.logoSicom-smAndDown {
  cursor: pointer;
  width: 140px;
  height: 65px;
}

.web-language-icon {
  margin-top: 2px;
  margin-right: 2px;
  font-size: 22px;
}
.language-btn {
  text-transform: capitalize;
  padding-left: 16px;
  padding-right: 16px;
}
.language-text {
  font-family: DM Sans !important;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0vw;
}
.language-list-item {
  min-height: 48px;
}
.language-list {
  border-radius: 0vw !important;
  max-height: auto !important;
}
.language-list-item-title {
  font-family: DM Sans !important;
  font-size: 14px;
}
.search-btn {
  padding-top: 3px;
}
.search-btn-margin {
  margin-left: 4px;
  margin-right: 4px;
}
.search-textfield {
  width: 160px;
}
.end-col {
  margin-right: 0px;
}

.header-menu-style {
  background-color: white;
  padding: 0px;
  margin-top: 15px;
  width: 1602px;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid #f4f4f4;
}
.header-menu-btn {
  font: DM Sans;
  text-transform: capitalize;
  color: #424242;
  font-weight: normal;
  text-decoration: none;
  font-size: 18px;
}
.header-submenu-btn {
  font: DM Sans;
  text-transform: capitalize;
  color: #424242;
  font-weight: normal;
  text-decoration: none;
  font-size: 16px;
}
.header-border {
  border-bottom: 1px solid #f4f4f4 !important;
}
.header-menu-row {
  height: 215px;
}
.header-rowbtn {
  padding-top: 25px !important;
}
.header-rowbtn2 {
  padding-top: 13px !important;
}
.header-rowbtn3 {
  padding-top: 7px !important;
}
</style>
<style scoped>
.effect:hover {
  font-weight: 600;
}
.v-menu__content {
  box-shadow: none !important;
}
.v-toolbar__content,
.v-toolbar__extension {
  padding: 0px 29px !important;
}
@media screen and (max-width: 769px) {
  .application-bar {
    background-color: rgb(255, 255, 255) !important;
  }
  .logoSicom-col {
    justify-content: center;
    align-items: center;
  }
  .logoSicom-col-smAndDown {
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logoSicom-smAndUp {
    padding-top: 13.02083vw;
    cursor: pointer;
    width: 18.229167vw;
    height: 8.463541667vw;
  }
  /* .logoSicom-smAndDown {
    padding-top: 13.02083vw;
    cursor: pointer;
    width: 18.229167vw !important;
    height: 8.463541667vw !important;
  } */

  .web-language-icon {
    margin-top: 0.470588235vw;
    margin-right: 0.470588235vw;
    font-size: 2.864583vw !important;
  }
  .language-btn {
    text-transform: capitalize;
    padding-left: 2.083vw !important;
    padding-right: 2.083vw !important;
  }
  .language-text {
    font-family: DM Sans !important;
    font-weight: 700;
    font-size: 2.083vw !important;
    letter-spacing: 0vw;
  }
  .language-list-item {
    min-height: 6.25vw !important;
  }
  .language-list {
    border-radius: 0vw !important;
    max-height: auto !important;
  }
  .language-list-item-title {
    font-family: DM Sans !important;
    font-size: 1.8229167vw !important;
  }
  .search-btn {
    padding-top: 0.390625vw;
    font-size: 10px !important;
  }
  .search-btn-margin {
    margin-left: 0.52083vw;
    margin-right: 0.52083vw;
  }
  .search-textfield {
    width: 15.83vw !important;
  }
  .end-col {
    margin-right: 0px;
  }

  .header-menu-style {
    background-color: white;
    padding: 0px;
    margin-top: 1.953125vw;
    width: 208.59375vw;
    margin-left: auto;
    margin-right: auto;
    border-top: 0.1302083vw solid #f4f4f4;
  }
  .header-menu-btn {
    font: DM Sans;
    text-transform: capitalize;
    color: #424242;
    font-weight: normal;
    text-decoration: none;
    font-size: 2.34375vw !important;
  }
  .header-submenu-btn {
    font: DM Sans;
    text-transform: capitalize;
    color: #424242;
    font-weight: normal;
    text-decoration: none;
    font-size: 2.083vw !important;
  }
  .header-border {
    border-bottom: 0.1302083vw solid #f4f4f4 !important;
  }
  .header-menu-row {
    height: 27.99479167vw;
  }
  .header-rowbtn {
    padding-top: 5.859375vw !important;
  }
  .header-rowbtn2 {
    padding-top: 4.167vw !important;
  }
  .header-rowbtn3 {
    padding-top: 3.3854167vw !important;
  }
}
@media screen and (max-width: 651px) {
  .logoSicom-smAndDown {
    padding-top: 13.02083vw;
    cursor: pointer;
    width: 21.5385vw !important;
    height: 10vw !important;
  }
}
</style>
<style>
@media screen and (max-width: 769px) {
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 0.2604167vw 1.302083vw !important;
  }
}
</style>
