import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
/* const VBtn = Vue.component('VBtn');
VBtn.options.props.ripple.default = false */

export default new Vuetify({
    breakpoint: {
      thresholds: {
        xs: 0,
        sm: 427,
        md: 779,
        lg: 1601,
      },
      scrollBarWidth: 10,
    },
  })

